// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


document.addEventListener("DOMContentLoaded", function (event) {
  var colors = {
    'red': 'red',
    'green': '#4FD659'
  };

  var elements = document.getElementsByClassName('radial');
  var element = elements[0]

  var radius = 85;
  var border = 15;
  var padding = 0;
  var startPercent = 0;
  var endPercent = element.dataset.percent;
  var color = colors.red

  if(endPercent > 0.30) {
    color = colors.green;
  }

  var twoPi = Math.PI * 2;
  var formatPercent = d3.format('.0%');
  var boxSize = (radius + padding) * 2;

  console.log(boxSize)


  var count = Math.abs((endPercent - startPercent) / 0.01);
  var step = endPercent < startPercent ? -0.01 : 0.01;

  var arc = d3.svg.arc()
    .startAngle(0)
    .innerRadius(radius)
    .outerRadius(radius - border)
    .cornerRadius(50);

  var parent = d3.select(element);

  var svg = parent.append('svg')
    .attr('width', boxSize)
    .attr('height', boxSize);

  var gradient = svg.append("svg:defs")
    .append("svg:linearGradient")
    .attr("id", "gradient")
    .attr("x1", "0%")
    .attr("y1", "50%")
    .attr("x2", "50%")
    .attr("y2", "0%")
    .attr("spreadMethod", "pad");

  gradient.append("svg:stop")
    .attr("offset", "0%")
    .attr("stop-color", color)
    .attr("stop-opacity", 1);

  gradient.append("svg:stop")
    .attr("offset", "100%")
    .attr("stop-color", color)
    .attr("stop-opacity", 1);

  var field = svg.append('g')
    .attr('transform', 'translate(' + boxSize / 2 + ',' + boxSize / 2 + ')');

  var meter = field.append('g')
    .attr('class', 'progress-meter');

  meter.append('path')
    .attr('class', 'background')
    .attr('fill', '#E9EEEF')
    .attr('fill-opacity', 1)
    .attr('d', arc.endAngle(twoPi));

  var front = meter.append('path')
    .attr('class', 'foreground')
    .attr('fill', 'url(#gradient)')
    .attr('fill-opacity', 1);

  var numberText = meter.append('text')
    .attr('fill', '#425667')
    .attr('text-anchor', 'middle')
    .attr('dy', '.278em')
    .attr('class', 'radial__text');

  function updateProgress(progress) {
    // foreground.attr('d', arc.endAngle(twoPi * progress));
    front.attr('d', arc.endAngle(twoPi * progress));
    numberText.text(formatPercent(progress));
  }

  updateProgress(endPercent)
});
